// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-img {
    width: 100%;
    display: none;
    overflow: hidden;
}

.footer-img.mobile {
    width: 100%;
    height: auto;
    display: block;
}

#bottom-sign-up-btn {
    position: fixed;
    bottom: 30px;
    display: none;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999;
    background-color: #FFF;
    padding: 10px;
    border-radius: 50px;
    border-left: 3px solid #222;
    border-right: 3px solid #222;
}

@media (min-width: 768px) {
    .footer-img.mobile {
        display: none;
    }
    .footer-img.web {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,cAAc;IACd,SAAS;IACT,2BAA2B;IAC3B,eAAe;IACf,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".footer-img {\n    width: 100%;\n    display: none;\n    overflow: hidden;\n}\n\n.footer-img.mobile {\n    width: 100%;\n    height: auto;\n    display: block;\n}\n\n#bottom-sign-up-btn {\n    position: fixed;\n    bottom: 30px;\n    display: none;\n    margin: 0 auto;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 999999;\n    background-color: #FFF;\n    padding: 10px;\n    border-radius: 50px;\n    border-left: 3px solid #222;\n    border-right: 3px solid #222;\n}\n\n@media (min-width: 768px) {\n    .footer-img.mobile {\n        display: none;\n    }\n    .footer-img.web {\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
