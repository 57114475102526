// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FAQ.css */
.faq-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.faq-container h2 {
    text-align: center;
    font-family: "Khand", sans-serif;
    font-weight: 600;
    font-size: 2.5rem;

    margin-top: 1rem;
}

.faq-list {
    margin-top: 20px;
}

.faq-item {
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 15px 30px;
    margin-bottom: 1rem;
}

.faq-question {
    font-weight: bold;
    cursor: pointer;
    color: #333;
}

.faq-answer {
    margin-top: 10px;
    color: #666;
    font-size: 0.9em;
}
`, "",{"version":3,"sources":["webpack://./src/FAQ/FAQ.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;;IAEjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["/* FAQ.css */\n.faq-container {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n}\n\n.faq-container h2 {\n    text-align: center;\n    font-family: \"Khand\", sans-serif;\n    font-weight: 600;\n    font-size: 2.5rem;\n\n    margin-top: 1rem;\n}\n\n.faq-list {\n    margin-top: 20px;\n}\n\n.faq-item {\n    border: 1px solid #000000;\n    border-radius: 50px;\n    padding: 15px 30px;\n    margin-bottom: 1rem;\n}\n\n.faq-question {\n    font-weight: bold;\n    cursor: pointer;\n    color: #333;\n}\n\n.faq-answer {\n    margin-top: 10px;\n    color: #666;\n    font-size: 0.9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
