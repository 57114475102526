// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./IMG/bg-detalhes.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

body {
  /* background-color: #F9F9F9; */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

h1 {
  font-family: "Khand", sans-serif;
}

p {
  font-family: "Roboto", sans-serif;
}

.container {
  width: min(90%, 1000px);
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  body {
    background-position: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,+BAA+B;EAC/B,yDAA8C;EAC9C,4BAA4B;EAC5B,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  font-family: sans-serif;\n  box-sizing: border-box;\n}\n\nbody {\n  /* background-color: #F9F9F9; */\n  background-image: url(\"./IMG/bg-detalhes.png\");\n  background-attachment: fixed;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  overflow-x: hidden;\n}\n\nh1 {\n  font-family: \"Khand\", sans-serif;\n}\n\np {\n  font-family: \"Roboto\", sans-serif;\n}\n\n.container {\n  width: min(90%, 1000px);\n  margin: 0 auto;\n}\n\n@media only screen and (max-width: 768px) {\n  body {\n    background-position: left;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
