// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup.active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s;
    z-index: 9999999;
}

.popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    width: min(90%, 600px);
    height: fit-content;
    max-height: 90vh;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: scroll;
}

.popup-content iframe {
    width: 100%;
    height: 700px;
}

.close-btn {
    float: right;
    border: none;
    cursor: pointer;
    background: none;
    margin-bottom: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/GoogleFormsPopUp/GoogleFormsPopUp.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,8BAA8B;IAC9B,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,uCAAuC;IACvC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".popup.active {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.7);\n    transition: opacity 0.5s;\n    z-index: 9999999;\n}\n\n.popup-content {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: #fff;\n    padding: 20px;\n    width: min(90%, 600px);\n    height: fit-content;\n    max-height: 90vh;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    overflow: scroll;\n}\n\n.popup-content iframe {\n    width: 100%;\n    height: 700px;\n}\n\n.close-btn {\n    float: right;\n    border: none;\n    cursor: pointer;\n    background: none;\n    margin-bottom: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
